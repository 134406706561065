export const HeaderNavigation = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "About Us",
    href: "#",
    subpages: [
      {
        title: "Company Overview",
        href: "/about-us/company-overview",
      },
      {
        title: "Vision  Mission",
        href: "/about-us/vision-mission",
      },
      {
        title: "CSR ",
        href: "/about-us/social-responsibilities",
      },
      // Add more subpages as needed
    ],
  },
  {
    title: "Products",
    href: "/products",
    subpages: [
      {
        title: "Antihypertensive",
        href: "/products",
      },
      {
        title: "Antidiabetic",
        href: "/products",
      },
      {
        title: "Pain management drugs",
        href: "/products",
      },
      {
        title: "Antibiotics",
        href: "/products",
      },
      {
        title: "Dermatological products",
        href: "/products",
      },
      {
        title: "Ophthalmic ",
        href: "/products",
      },
      {
        title: "Dermatology ",
        href: "/products",
      },
      {
        title: "Injectables  ",
        href: "/products",
      },
      // Add more subpages as needed
    ],
  },

  {
    title: "Contact Us",
    href: "/contact-us",
  },
];
