import React from "react";

const Products = () => {
  return (
    <div>
      <div class="pb-20 bg-[#ddf2ff] text-[#161345]">
        <div class=" lg:py-20 py-5 text-center ">
          <h2 className="text-5xl font-semibold text-[#161345]">
            Our Products
          </h2>
          <i class="fa-solid fa-arrow-down pt-10"></i>
        </div>
        <div className="text-[#161345] flex flex-wrap justify-center items-center lg:text-left text-center">
          <div>
            <span className="uppercase font-semibold text-sm">TOP PRODUCT</span>
            <h2 className="lg:text-6xl text-[30px] font-semibold lg:w-[600px]">
              PHARMACEUTICAL PRODUCTS
            </h2>
          </div>
          <div className="space-y-4">
            <p className="lg:w-[450px]  text-lg">
              We are into different segments of Pharmaceutical product as
              mentioned below:
            </p>
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">Kalther 20/120 Tablets</div>

              <span className="text-[13px]">
                Artemether 20mg + <br />
                Lumefantrine 120mg Tablets
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">Kalther 80/480 Tablets</div>

              <span className="text-[13px]">
                Artemether 80mg + <br />
                Lumefantrine 480mg Tablets
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">
                Kalther 20/120 Solf <br />
                Galetine Capsules
              </div>

              <span className="text-[13px]">
                Artemether Ph.Int. 20mg + <br />
                Lumefantrine Ph.Int.120mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">
                KALTHER FORTE 80/480 <br />
                SOFT GELATINE CAPSULES
              </div>

              <span className="text-[13px]">
                Artemether Ph.Int. 80mg + <br />
                Lumefantrine Ph. Int. 480mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">ACIZEK </div>

              <span className="text-[13px]">
                Omeprazole Delayed Release <br />
                Capsules USP 20mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">RABEKAL 20</div>

              <span className="text-[13px]">
                Rabeprazole Sodium <br />
                Tablets 20mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">FEROKAL</div>

              <span className="text-[13px]">
                Ferrous Fumarate with Vit B12, <br />
                Folic Acid and Zinc
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">WELLANA </div>

              <span className="text-[13px]">
                Softgels of Multivitamin & Minerals <br />
                with Ginseng & Aloe Vera
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">ZEICIN </div>

              <span className="text-[13px]">
                Ciprofloxacin Tablets <br />
                USP 500mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">GSPORIN </div>

              <span className="text-[13px]">
                Gentamicin Sulphate <br />
                0.3% w/v
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">AMIKAL 375 </div>

              <span className="text-[13px]">
                Amoxicillin 250mg + <br />
                Clavulanic Acid 125mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">AMIKAL 625 </div>

              <span className="text-[13px]">
                Amoxicillin 500mg + <br />
                Clavulanic Acid 125mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">AMIKAL 1000 </div>

              <span className="text-[13px]">
                Amoxicillin 825 mg + <br />
                Clavulanic Acid 125mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALBID </div>

              <span className="text-[13px]">
                Cefuroxime Axetil <br />
                500mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">ERYKAL</div>

              <span className="text-[13px]">
                Erythromycin Stearate Tablets <br />
                BP 500mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALSTAT 10 </div>

              <span className="text-[13px]">
                Rosuvastatin <br />
                10mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALSTAT 20 </div>

              <span className="text-[13px]">
                Rosuvastatin <br />
                20mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">MYOTEL 10</div>

              <span className="text-[13px]">
                Atorvastatin Calcium Tablets
                <br />
                USP 10mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">MYOTEL TG </div>

              <span className="text-[13px]">
                Atorvastatin 10mg +
                <br />
                Finofibrate Tablets 160mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALBLOC 5 </div>

              <span className="text-[13px]">
                Amlodipine <br />
                5mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALBLOC 10</div>

              <span className="text-[13px]">
                Amlodipine <br />
                10mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALACE 2.5 </div>

              <span className="text-[13px]">
                Ramipril
                <br />
                2.5mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALACE 5 </div>

              <span className="text-[13px]">
                Ramipril
                <br />
                5mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">ENERKAL</div>

              <span className="text-[13px]">
                Glucosamine 250 + Chondroitin 250 <br />+ Vitamine D3 400 i.u +
                Fish oil 250
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">ENERKAL-M </div>

              <span className="text-[13px]">
                Glucosamine 444 +
                <br />
                Methylsulfonylmethane 250
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">AMIFLAM </div>

              <span className="text-[13px]">
                Ibuprofen 400mg
                <br />
                Tablets
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALPHAGE 500</div>

              <span className="text-[13px]">
                Matformin BP <br /> 500mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALPHAGE 850 </div>

              <span className="text-[13px]">
                Matformin BP <br /> 850mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KLIM PLUS 1 </div>

              <span className="text-[13px]">
                Glimepiride BP 1mg + Metformin <br /> Hydrochloride BP 500mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KLIM PLUS 2 </div>

              <span className="text-[13px]">
                Glimepiride BP 2mg + Metformin <br /> Hydrochloride BP 500mg
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">FLUKAL 150 TABLETS </div>

              <span className="text-[13px]">
                Fluconazole Tablets <br /> USP 150mg
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">ACIKAL CREAM</div>

              <span className="text-[13px]">
                Aciclovir Cream <br /> BP 5% W/W
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALCEP 100 </div>

              <span className="text-[13px]">
                Cefpodoxime 100mg <br /> Tablets
              </span>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center items-center gap-2  pt-10">
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALCEP 200 </div>

              <span className="text-[13px]">
                Cefpodoxime 200mg <br /> Tablets
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALCEP 50 SYRUP</div>

              <span className="text-[13px]">
                Cefpodoxime 50mg/5ml Powder <br /> for oral suspension
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-16 px-10 py-4 bg-white w-[300px] h-[220px]">
            <div>
              <div className="font-bold">KALCEP 100 SYRUP </div>

              <span className="text-[13px]">
                Cefpodoxime 100mg/5ml Powder <br /> for oral suspension
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
