import React from "react";
import testimonialimg from "../Assets/testimonial-practitioner-copyright-120x120.jpg";
import Slider from "react-slick"; // or appropriate import for your library

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsComponent = () => {
  const bgTestimonial = {
    backgroundImage: `url(${testimonialimg})`,
  };

  // Similarly for SamplePrevArrow

  var settings = {
    // dots: true,
    nextArrow: <SampleNextArrow />,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true, // Ensure arrows are enabled for this breakpoint
          slidesToShow: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, color: "red" }}
        onClick={onClick}
      />
    );
  }
  return (
    <div className="bg-[#ddf2ff] lg:w-[540px] w-[300px] lg:m-0 m-auto  px-10">
      <Slider
        className=""
        autoplay
        autoplaySpeed={3000}
        infinite={true}
        {...settings}
      >
        <div>
          <div className=" flex flex-col justify-center items-center text-center py-36 lg:px-16 gap-5">
            <div
              className="w-[120px] h-[120px] m-auto rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>

            <p className="text-[#161345]  font-semibold text-lg">
              Pushkal Pharma offers quality medicine with effective results.
              Their products are reliable and trustworthy, ensuring the
              well-being of their customers. Highly recommended for medical
              needs.
            </p>
            <i className="fa-solid fa-quote-left text-5xl text-[#0076BC]"></i>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686]">ABC Group</span>
            </p>
          </div>
        </div>
        <div>
          <div className="flex flex-col justify-center items-center text-center py-36 lg:px-16 gap-5">
            <div
              className="w-[120px] h-[120px] m-auto rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>

            <p className="text-[#161345]  font-semibold text-lg">
              Pushkal Pharma offers quality medicine with effective results.
              Their products are reliable and trustworthy, ensuring the
              well-being of their customers. Highly recommended for medical
              needs.
            </p>
            <i className="fa-solid fa-quote-left text-5xl text-[#0076BC]"></i>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686]">ABC Group</span>
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default TestimonialsComponent;
