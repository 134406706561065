import React from "react";
import csr2 from "../Assets/csr1.jpeg";
import csr4 from "../Assets/csr2.jpeg";
import csr3 from "../Assets/csr3.jpeg";
import csr1 from "../Assets/csr4.jpeg";

const SocialResponsibilities = () => {
  const containerStyle = {
    background: "url(" + csr2 + ") center/cover",
    // backgroundColor: "#1C242970", // Set your desired background color
  };
  const containerStyle1 = {
    background: "url(" + csr4 + ") center/cover",
    // backgroundColor: "#1C242970", // Set your desired background color
  };
  const containerStyle2 = {
    background: "url(" + csr3 + ") center/cover",
    // backgroundColor: "#1C242970", // Set your desired background color
  };
  const containerStyle3 = {
    background: "url(" + csr1 + ") center/cover",
    // backgroundColor: "#1C242970", // Set your desired background color
  };
  return (
    <div>
      {/* <div class="pb-20 bg-[#ddf2ff] text-[#161345]">
        <div class="section_overview lg:py-20 py-5 text-center">
          <h2 className="font-semibold  text-5xl">Social Responsibilities</h2>
          <i class="fa-solid fa-arrow-down pt-10"></i>
        </div>

        <div class="flex flex-wrap justify-center items-center gap-20">
          <div class="">
            <img src={require("../Assets/companyOverview.jpg")} />
          </div>

          <div class="px-3">
            <h4 class="lg:text-[50px] lg:w-[300px] lg:text-left font-semibold text-center">
              SOCIAL RESPONSIBILITIES
            </h4>
            <p class="lg:w-[400px] lg:text-left text-center">
              We are committed towards our social responsibilities in respective
              countries by the way of student welfare, child malnutrition, women
              empowerment, and girl child protection.
            </p>
            <br />
            <p class="lg:w-[400px] lg:text-left text-center">
              We conduct free medical camps to provide free medicines to needy
              patients and are also involved in managing welfare schools. We
              also provide necessary scholarship to brilliant needy students for
              further studies.
            </p>
          </div>
        </div>
      </div> */}

      <div className="bg-[#f9a534] flex justify-center flex-col lg:flex-row px-20 items-center  py-10">
        <div className=" lg:px-0 px-3 lg:space-y-0 space-y-3">
          <h2 className="lg:text-[40px] text-[30px] lg:-mb-14 lg:px-12 lg:w-[650px] ">
            A Soul Satisfying approuch
          </h2>
          <p className="text-lg bg-white px-8 py-16 lg:-mt-20 lg:w-[350px] w-[300px] text-justify">
            As a corporate citizen, Pushkal takes its social role seriously of
            sowing the seeds of corporate responsibility for the community.{" "}
            <br />
            <br />
            On the 26th of each month, teams of Pushkal volunteers go to schools
            and community centers where lesser privileged children are fed
            nutritious meals sponsored by the Company. <br />
            <br />
            Volunteers distribute books and educational materials while programs
            on personal hygiene for adolescent girls are also held.
          </p>
        </div>
        <div className="flex flex-wrap gap-2 lg:px-0 px-3 lg:-ml-[300px]">
          <div className="space-y-2">
            <div
              className="lg:w-[200px] lg:h-[200px] lg:ml-[200px] w-[300px] h-[300px]"
              style={containerStyle3}
            ></div>
            <div
              className="lg:w-[400px] lg:h-[220px] w-[300px] h-[300px]"
              style={containerStyle}
            ></div>
          </div>
          <div className="space-y-2">
            <div
              className="lg:w-[200px] lg:h-[200px] w-[300px] h-[300px]"
              style={containerStyle2}
            ></div>
            <div
              className="lg:w-[200px] lg:h-[200px] w-[300px] h-[300px] rounded-full"
              style={containerStyle1}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialResponsibilities;
