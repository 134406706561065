import { React } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../Components/Header/Header";
import Home from "../Pages/Home";
import CompanyOverview from "../Pages/CompanyOverview";
import Footer from "../Components/Footer";
import OurJourney from "../Pages/OurJourney";
import VisionMission from "../Pages/VisionMission";
import SocialResponsibilities from "../Pages/SocialResponsibilities";

import ContactUs from "../Pages/ContactUs";
import Testimonials from "../Pages/Testimonials";
import Products from "../Pages/Products";

const AppRoutes = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/products" element={<Products />} />
        <Route
          path="/about-us/company-overview"
          element={<CompanyOverview />}
        />
        <Route path="/about-us/our-journey" element={<OurJourney />} />
        <Route path="/about-us/vision-mission" element={<VisionMission />} />
        <Route
          path="/about-us/social-responsibilities"
          element={<SocialResponsibilities />}
        />
      </Routes>

      <Footer />
    </Router>
  );
};

export default AppRoutes;
