import React from "react";

const CompanyOverview = () => {
  return (
    <div>
      <div class="pb-20 bg-[#ddf2ff] text-[#161345]">
        <div class=" lg:py-20 py-5 text-center ">
          <h2 className="text-5xl font-semibold text-[#161345]">
            Company Overview
          </h2>
          <i class="fa-solid fa-arrow-down pt-10"></i>
        </div>

        <div class="flex flex-wrap justify-center items-center gap-10">
          <div class="">
            <img src={require("../Assets/companyOverview.jpg")} />
          </div>

          <div class="px-3">
            <h3 class="lg:text-[50px] lg:w-[300px] lg:text-left font-semibold text-center">
              ABOUT US
            </h3>
            <br />
            <p class="lg:w-[540px] lg:text-left text-center">
              At Pushkal, we're a dynamic startup hoping to revolutionise the
              healthcare sector with our innovative approach and unwavering
              commitment to excellence. Our mission is to transform human health
              by developing high-quality medicines that make a real difference.
              Founded by a passionate team driven by a shared vision, we are
              dedicated to improving lives through meticulous research and
              cutting-edge solutions. We focus on understanding the unique
              physiological needs of our customers, ensuring that our products
              meet the highest standards of quality and care.
            </p>
            <br />
            <p class="lg:w-[540px] lg:text-left text-center">
              Our flexible and responsive operations allow us to stay ahead of
              the curve, quickly adapting to the evolving needs of the
              healthcare market. By leveraging the latest technologies and a
              customer-centric approach, we are committed to delivering
              exceptional value and improving health outcomes for communities
              worldwide.
            </p>{" "}
            <br />
            <h3 className="text-[20px]  lg:text-left text-center font-bold">
              Our Values:
            </h3>{" "}
            <br />
            <ul class="lg:w-[540px] ">
              <li>
                <span className="font-bold">Innovation:</span> Constantly
                pushing the boundaries to develop groundbreaking healthcare
                solutions.
              </li>
              <li>
                <span className="font-bold">Quality:</span> Ensuring every
                product we deliver meets the highest standards.
              </li>
              <li>
                <span className="font-bold">Integrity:</span> Conducting our
                business with the utmost honesty and transparency.
              </li>
              <li>
                <span className="font-bold">Compassion:</span> Putting the
                well-being of our customers at the heart of everything we do.
              </li>
            </ul>
          </div>
        </div>

        <div class=" lg:px-[120px] px-3 lg:text-left text-center  py-10 my-8">
          <h3 className="lg:text-[40px] lg:text-left font-semibold ">
            WHO WE ARE
          </h3>
          <br />
          <p>
            Pushkal is a Pharmaceuticals Company in Nigeria providing quality
            and affordable medicines in respective Pharma segments. We have
            expertise in providing different formulations in different
            pharmaceuticals segments at affordable price.
          </p>
          <br />
          <p>
            We have around 100 different trademarks registered in Nigeria, under
            which we market our products. In near future we will be having our
            own liquid manufacturing plant in Ogun state, Nigeria
          </p>
        </div>
        <div class="lg:px-[120px] px-3 lg:text-left text-center py-6 my-8">
          <h3 className="lg:text-[40px] lg:text-left font-semibold">
            WHAT WE DO
          </h3>
          <br />
          <p>
            Pushkal pharmaceuticals Pvt. Ltd is a technical collaborator of
            PANACHY UK LTD. which is based in London, U.K.
          </p>
          <br />
          <p>
            We are specialized in different drug delivery system via delayed
            release formulation, soft gelatin capsules, enteric coated products,
            time released dosage form etc.
          </p>
          <br />
          <p>
            We have very efficient marketing staff and strong marketing network
            all over Nigeria with depot in the following major cities,
          </p>
          <br />
          <ul class="">
            <li>LAGOS</li>
            <li>ABUJA</li>
            <li>KANO</li>
            <li>ONITSHA</li>
            <li>PORT-HARCOURT</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;
