import React from "react";

const ContactUs = () => {
  return (
    <div class="pt-20 bg-[#ddf2ff] text-[#161345]">
      <div class=" lg:py-20 py-5 text-center ">
        <h2 className="lg:text-5xl text-4xl font-semibold">Conatact Us</h2>
        <i class="fa-solid fa-arrow-down pt-10"></i>
      </div>

      <div className="flex flex-wrap justify-center gap-32">
        <div className="flex flex-col gap-y-2 justify-center items-center text-center">
          <span>
            <i className="fa-solid fa-clock text-[3.4em] text-[#0076BC]"></i>
          </span>
          <span className="text-[30px] font-semibold">Open Hour</span>
          <span className="text-lg">
            Mon-Fri: 9 AM – 6 PM <br />
            Saturday: 9 AM – 4 PM <br />
            Sunday: Closed
          </span>
        </div>
        <div className="flex flex-col justify-center items-center text-center">
          <span>
            <i className="fa-solid fa-location-dot text-[3.4em] text-[#0076BC]"></i>
          </span>
          <span className="text-[30px] font-semibold">Address</span>
          <span className="text-lg">
            PLot 7, Block 1, Ota Industrial <br />
            Estate, Ota - Ogun <br />
            State, Nigeria
          </span>
        </div>
        <div className="flex flex-col justify-center items-center text-center">
          <span>
            <i class="fa-solid fa-headphones text-[3.4em] text-[#0076BC]"></i>
          </span>
          <span className="text-[30px] font-semibold">Get In Touch</span>
          <span className="text-lg">
            +234 809 431 5555 <br /> / 809 432 5555
            <br />
          </span>
        </div>
      </div>

      <div id="contactaddress" className="flex flex-wrap w-full pt-20">
        <iframe
          className="lg:w-[50%] w-full lg:h-[800px] h-[600px]"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3867187.666169696!2d76.76983739999999!3d18.81817715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707508598139!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <div className="bg-white lg:w-[50%] lg:px-20 px-10 lg:py-0 py-5 flex flex-col justify-center ">
          <span className="uppercase font-semibold text-sm">CONTACT US</span>
          <h2 className="lg:text-6xl text-3xl font-semibold lg:w-[500px] w-[300px] ">
            Have Questions? Get in touch!
          </h2>
          <p className="lg:w-[450px] text-lg">
            This is Pushkal Pharmaceutical Ltd.
          </p>
          {/* Form */}
          <div className="py-10" id="contactform">
            <form className="form lg:px-0 ">
              <div className="mb-4 flex flex-wrap">
                <div className="w-1/2 pr-2 relative ">
                  <input
                    required
                    placeholder="Full name "
                    type="text"
                    id="name"
                    name="name"
                    // onChange={handleInputChange}
                    className=" border-gray-300 w-full border  px-6 py-2"
                  />
                  <i className="fa-solid fa-user absolute right-4 top-3 text-gray-400"></i>
                </div>

                <div className="w-1/2 pl-2 relative">
                  <input
                    required
                    placeholder="Email"
                    type="email"
                    id="email"
                    name="email"
                    // onChange={handleInputChange}
                    className="border-gray-300 w-full border  px-6 py-2 "
                  />
                  <i className="fa-regular fa-envelope absolute right-3 top-3 text-gray-400"></i>
                </div>
              </div>

              <div className="mb-4 ">
                <div className="relative">
                  <input
                    required
                    placeholder="Phone number"
                    type="number"
                    id="number"
                    name="phone"
                    // onChange={handleInputChange}
                    className="border-gray-300 w-full border px-6 py-2   "
                  />
                  <i className="fa-solid fa-square-arrow-up-right absolute right-3 top-3 text-gray-400"></i>
                </div>
              </div>

              <div className="mb-4">
                <textarea
                  required
                  placeholder=" Message"
                  id="message"
                  name="message"
                  // onChange={handleInputChange}
                  className="border-gray-300 w-full border  px-6 py-2 "
                ></textarea>
              </div>

              <div className="  ">
                <button className="bg-[#0076BC] rounded-lg my-10 py-4 px-8 uppercase text-white font-semibold hover:bg-white hover:border-[#0076BC] hover:border-2 hover:rounded-lg hover:text-[#0076BC] transition-all">
                  <i className="fa-brands fa-telegram fa-beat-fade"></i> Get In
                  Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
