import React from "react";

const Footer = () => {
  const footerImag = [
    {
      image: `${require("../Assets/vet-insta1-copyright-370x370.jpg")}`,
    },
    {
      image: `${require("../Assets/vet-insta2-copyright-370x370.jpg")}`,
    },
    {
      image: `${require("../Assets/vet-insta3-copyright-370x370.jpg")}`,
    },
    {
      image: `${require("../Assets/vet-insta4-copyright-370x370.jpg")}`,
    },
    {
      image: `${require("../Assets/vet-insta5-copyright-370x370.jpg")}`,
    },
    {
      image: `${require("../Assets/vet-insta6-copyright-370x370.jpg")}`,
    },
  ];

  const footerMenu = [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "Company Overview",
      href: "/about-us/company-overview",
    },
    {
      title: "Vision Mission",
      href: "/about-us/vision-mission",
    },

    {
      title: "Social Responsibilities",
      href: "/about-us/social-responsibilities",
    },
    {
      title: "Contact Us",
      href: "/contact-us",
    },
  ];
  return (
    <div className="bg-[#171828] pt-24">
      {/* <div className="flex flex-row justify-center items-center gap-2 text-white ">
        <i class="fa-brands fa-instagram"></i>
        <span className="font-bold">instagram</span>
      </div>
      <div className="flex flex-wrap justify-center gap-8 py-10">
        {footerImag.map((route) => {
          const { image } = route;
          return <img className="w-[160px] hover:opacity-80" src={image} />;
        })}
      </div> */}

      <div className="flex flex-col justify-center items-center py-6 gap-8">
        <img
          className="w-[160px]"
          src={require("../Assets/main-logo-white.png")}
          alt="Pushkal Logo"
        />
        <ul className="flex flex-wrap justify-center items-center gap-8 text-white font-bold">
          {footerMenu.map((route) => {
            const { title, href } = route;
            return (
              <li className="group relative" key={(title, href)}>
                <a href={href} className="">
                  {title}
                </a>
                <span className="absolute bottom-0 left-0 w-0 bg-[#0076BC] h-0.5 group-hover:w-full transition-all duration-300"></span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="border-b-[1px] border-[#ffffff33] pt-8 pb-20 mx-20 flex justify-center gap-2 text-white cursor-pointer">
        <span className="border border-[#ffffff33] px-3 py-2 rounded-full">
          <i class="fa-brands fa-facebook hover:text-[#1877F2]"></i>
        </span>
        <span className="border border-[#ffffff33] px-3 py-2 rounded-full">
          <i class="fa-brands fa-instagram hover:text-[#C13584]"></i>
        </span>
        <span className="border border-[#ffffff33] px-3 py-2 rounded-full">
          <i class="fa-brands fa-x-twitter hover:text-[#1DA1F2]"></i>
        </span>
        <span className="border border-[#ffffff33] px-3 py-2 rounded-full">
          <i class="fa-brands fa-linkedin hover:text-[#0077b5]"></i>
        </span>
      </div>
      <div className="text-center py-4 text-white font-bold text-xl">
        Pushkal Pharma © 2024. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
