import React from "react";
import testimonialimg from "../Assets/testimonial-practitioner-copyright-120x120.jpg";

const Testimonials = () => {
  const bgTestimonial = {
    backgroundImage: `url(${testimonialimg})`,
  };
  return (
    <div class="pb-20 bg-[#ddf2ff] text-[#161345]">
      <div class=" lg:py-20 py-5 text-center ">
        <h2 className="text-5xl font-semibold text-[#161345]">Testimonials</h2>
        <i class="fa-solid fa-arrow-down pt-10"></i>
      </div>

      <div className="flex flex-wrap justify-center gap-10 mb-10 ">
        <div className="bg-white px-20 py-10 space-y-6">
          <span>
            <i className="fa-solid fa-quote-left text-[3em] text-[#0076BC]"></i>
          </span>
          <p className="font-semibold">
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br /> labore
            et dolore magna aliqua ut enim ad minim. <br />
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br />
            labore.
          </p>
          <div className="flex items-center gap-3">
            <div
              className="w-[80px] h-[80px]  rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686] text-sm">ABC Group</span>
            </p>
          </div>
        </div>
        <div className="bg-white px-20 py-10 space-y-6">
          <span>
            <i className="fa-solid fa-quote-left text-[3em] text-[#0076BC]"></i>
          </span>
          <p className="font-semibold">
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br /> labore
            et dolore magna aliqua ut enim ad minim. <br />
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br />
            labore.
          </p>
          <div className="flex items-center gap-3">
            <div
              className="w-[80px] h-[80px]  rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686] text-sm">ABC Group</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-10 mb-10">
        <div className="bg-white px-20 py-10 space-y-6">
          <span>
            <i className="fa-solid fa-quote-left text-[3em] text-[#0076BC]"></i>
          </span>
          <p className="font-semibold">
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br /> labore
            et dolore magna aliqua ut enim ad minim. <br />
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br />
            labore.
          </p>
          <div className="flex items-center gap-3">
            <div
              className="w-[80px] h-[80px]  rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686] text-sm">ABC Group</span>
            </p>
          </div>
        </div>
        <div className="bg-white px-20 py-10 space-y-6">
          <span>
            <i className="fa-solid fa-quote-left text-[3em] text-[#0076BC]"></i>
          </span>
          <p className="font-semibold">
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br /> labore
            et dolore magna aliqua ut enim ad minim. <br />
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br />
            labore.
          </p>
          <div className="flex items-center gap-3">
            <div
              className="w-[80px] h-[80px]  rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686] text-sm">ABC Group</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-10 mb-10 ">
        <div className="bg-white px-20 py-10 space-y-6">
          <span>
            <i className="fa-solid fa-quote-left text-[3em] text-[#0076BC]"></i>
          </span>
          <p className="font-semibold">
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br /> labore
            et dolore magna aliqua ut enim ad minim. <br />
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br />
            labore.
          </p>
          <div className="flex items-center gap-3">
            <div
              className="w-[80px] h-[80px]  rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686] text-sm">ABC Group</span>
            </p>
          </div>
        </div>
        <div className="bg-white px-20 py-10 space-y-6">
          <span>
            <i className="fa-solid fa-quote-left text-[3em] text-[#0076BC]"></i>
          </span>
          <p className="font-semibold">
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br /> labore
            et dolore magna aliqua ut enim ad minim. <br />
            Adipiscing elit, sed do eiusmod tempor incididunt ut <br />
            labore.
          </p>
          <div className="flex items-center gap-3">
            <div
              className="w-[80px] h-[80px]  rounded-full bg-cover bg-no-repeat"
              style={bgTestimonial}
            ></div>
            <p className="font-semibold text-lg">
              Aman Singh <br />
              <span className="text-[#676686] text-sm">ABC Group</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
