import React from "react";
import bannerImg from "../Assets/pushkalbanner1.jpg";
import services1 from "../Assets/vet-insta5-copyright-370x370.jpg";
import products1 from "../Assets/veterinary-clinic-aid-kit.svg";
import services2 from "../Assets/vet-insta1-copyright-370x370.jpg";
import "../Pages/CommonStyle/Style.css";

import TestimonialsComponent from "../Components/TestimonialsComponent";
import Slider from "react-slick";
import InstagramData from "../Components/InstagramData";

const Home = () => {
  const bgportfolio = {
    backgroundImage: `url(${bannerImg})`,
  };
  const bgservices1 = {
    backgroundImage: `url(${services1})`,
  };
  const bgservices2 = {
    backgroundImage: `url(${services2})`,
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {/* Banner */}
      <div>
        <div
          style={bgportfolio}
          className="w-full lg:h-[600px] h-[300px] bg-cover relative  bg-center"
        >
          {/* <div className="absolute inset-0 bg-black bg-opacity-60"></div>
          <div className="flex flex-col justify-center items-center h-full z-10">
            <h1 className="text-white lg:text-[60px] text-[30px] z-0  text-center leading-[1]">
              {" "}
              Medicine for life
            </h1>
          </div> */}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center text-center pt-32 px-20">
        <p className="text-[25px]">
          We are a passionate startup dedicated to revolutionizing healthcare
          with innovative, high-quality solutions. Our mission is to improve
          human health and well-being.
        </p>
        <button className="bg-[#0076BC] my-10 py-4 px-8 uppercase rounded-md text-white font-semibold hover:bg-white hover:border-[#0076BC] hover:border-2 hover:rounded-lg hover:text-[#0076BC] transition-all ">
          Learn More
        </button>
      </div>
      {/* <InstagramData /> */}

      {/* Products Card*/}
      <div className="flex  flex-wrap justify-center items-center bg-[#DDF2FF] py-20">
        <div className="w-[500px] space-y-3 ">
          <div className="text-xl lg:text-left text-center px-2">
            <p>
              <i className="fa-solid fa-quote-left  text-[#0076BC] pr-2"></i>
              We relish the challenge to be constantly asigned to the
              community's healthcare needs, purpose and shared values.
            </p>
            <p className="my-[20px]">
              Together, we shell strive to draw the blueprint of a disease-free{" "}
              society.{" "}
              <i className="fa-solid fa-quote-right  text-[#0076BC]"></i>
            </p>
            <span className="font-bold ">Late Shashi Modi</span>
            <br />
            <span>Founder</span>
          </div>

          <div></div>
        </div>
        <img className="w-[300px]" src={require("../Assets/shashimodi.png")} />
      </div>

      {/* Responsibilities */}
      <div
        className="relative bg-cover bg-center text-center py-40 space-y-4"
        style={bgservices2} // assuming this contains your background image
      >
        {/* Overlay div */}
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="relative flex flex-wrap flex-col gap-5 z-10">
          <h3 className="lg:text-6xl text-[20px] text-[#0076BC] font-semibold">
            Our Products
          </h3>
          <p className="lg:mx-20 text-lg text-white">
            With expertise in different formulations for various healthcare
            segments, Pushkal has around <br />
            100 trademarks and registrations in Nigeria which are available in
            the country. An efficient and professional.
          </p>
          <a href="/products">
            <button className="bg-[#0076BC] my-10 py-4 px-8 uppercase rounded-md text-white font-semibold hover:bg-white hover:border-[#0076BC] hover:border-2 hover:rounded-lg hover:text-[#0076BC] transition-all ">
              View Our Products
            </button>
          </a>
        </div>
      </div>

      {/* Contact Us  */}
      <div className="flex flex-wrap justify-center py-20  gap-20">
        <div className="text-[#161345] text-center lg:text-left">
          <span className="uppercase font-semibold text-sm">CONTACT US</span>
          <h2 className="lg:text-6xl text-[20px] font-semibold lg:w-[500px]">
            Have Questions? Get in touch!
          </h2>
          <p className="lg:w-[450px] text-lg">
            This is Pushkal Pharmaceutical Ltd.
          </p>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-5   ">
          <div className="text-[#676686] font-semibold space-y-4">
            <span className="flex gap-2 items-start">
              <i className="fa-solid fa-location-dot  text-[#0076BC]"></i> Plot
              7, Block 1, Ota Industrial <br /> Estate, Ota - Ogun State,
              Nigeria
            </span>
            <span className="flex gap-2 items-start">
              <i className="fa-solid fa-mobile text-[#0076BC]"></i> +234 809 431
              5555 <br /> / 809 432 5555
            </span>
            <span className="flex gap-2 items-start">
              <i className="fa-regular fa-clock text-[#0076BC]"></i> Mon-Fri: 9
              AM – 6 PM <br />
              Saturday: 9 AM – 4 PM <br />
              Sunday: Closed
            </span>
            <span className="flex gap-2 items-start">
              <i className="fa-solid fa-envelope text-[#0076BC]"></i>{" "}
              info@pushkalpharma.com
            </span>
          </div>
          <div>
            <iframe
              className="lg:w-[250px] w-[350px] h-[300px]"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3867187.666169696!2d76.76983739999999!3d18.81817715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707508598139!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
