import { TOGGLE_MENU, CLOSE_MENU } from "../actionTypes";

const INIT_STATE = {
  isMenuOpen: false,
};

const MenuReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case CLOSE_MENU:
      return {
        ...state,
        isMenuOpen: false,
      };
    default:
      return { ...state };
  }
};

export default MenuReducer;
