import React from "react";

const VisionMission = () => {
  return (
    <div>
      <div class="pb-20 bg-[#ddf2ff] text-[#161345]">
        <div class=" lg:py-20 py-5 text-center ">
          <h2 className="text-5xl font-semibold text-[#161345]">
            Vision Mission
          </h2>
          <i class="fa-solid fa-arrow-down pt-10"></i>
        </div>
        <div class=" lg:px-28 px-3 lg:text-left text-center ">
          <h3 className="lg:text-[40px] lg:text-left font-semibold ">VISION</h3>
          <br />
          <p>
            Our vision for the upcoming years will focus around expanding our
            products base, by continuing research into ongoing health issues,
            which affects a wide range of people. Our success stems from gaining
            extensive knowledge about condition proceeding to develop products
            that make a visible impact quickly and effectively.
          </p>
          <br />
          <h3 className="lg:text-[40px] lg:text-left font-semibold ">
            MISSION
          </h3>
          <br />
          <p>
            At Pushkal Pharma our mission is to benefit the quality of human
            healthcare and promote positive healthcare by developing good
            quality medicine for human healthcare.
          </p>
          <br />
          <p>
            Our core value incorporates dedication, discipline, commitment and
            constant endeavor to better ourselves in providing high quality
            pharmaceuticals products.
          </p>
          <br />
          <p>
            In our goals, we are not just confined in leveraging profit but we
            understand the essence of medicine in people’s life thereby also
            practice the noble and humanitarian aspects of service ensuring that
            it reaches the door of all who need it
          </p>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
