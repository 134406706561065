import { React, useRef, useState } from "react";
import { ArrowDown, ArrowUp } from "../../Icons";

import { HeaderNavigation } from "./HeaderNavigation";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { toggleMenu } from "../../Redux/MenuToggle/actions";
import { AiOutlineMenuFold } from "react-icons/ai";
import Hamburger from "hamburger-react";

const Header = () => {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector((state) => state.MenuReducer);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleToggle = (e) => {
    e.preventDefault();

    dispatch(toggleMenu(!isMenuOpen));
    setOpenSubMenu(null);
  };
  // Close the menu when a menu item is clicked
  const handleMenuItemClick = () => {
    if (isMenuOpen) {
      dispatch(toggleMenu(false));
    }
  };

  const handleSubMenuToggle = (idx) => {
    setOpenSubMenu(openSubMenu === idx ? null : idx);
  };

  const SubMenu = ({ subpages }) => (
    <ul className="grid gap-2">
      {subpages.map((subpage) => (
        <li
          key={subpage.title}
          className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr"
        >
          <Link
            to={subpage.href}
            className="flex items-center justify-between w-full p-3 text-black rounded-xl bg-[#fff]"
            onClick={handleMenuItemClick}
          >
            <span className="flex gap-1 text-lg">{subpage.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
  return (
    <div>
      <header className="py-10">
        <div className="flex flex-wrap justify-between items-center px-16">
          <div className="hidden lg:block lg:flex">
            <img
              className="w-[200px]"
              src={require("../../Assets/main-logo-white.png")}
              alt="Pushkal Logo"
            />
          </div>
          <div className="flex flex-wrap justify-between items-center px-16">
            <div className="flex justify-between items-center  px-2">
              <ul className="hidden lg:flex lg:items-center md:gap-2 md:flex space-x-5 flex-wrap justify-between text-[#003152]  font-semibold ">
                {HeaderNavigation.map((route) => {
                  const { title, href, subpages } = route;
                  return (
                    <li className="group relative z-10" key={(title, href)}>
                      {subpages ? (
                        <>
                          <a href={href} className="hover:text-[#003152]">
                            {title}
                          </a>
                          <ul className="absolute -left-6 mt-2 space-y-2 bg-white border  invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 delay-100">
                            {subpages.map((subpage) => (
                              <li
                                key={subpage.title}
                                className="hover:bg-[#0076BC] w-[250px] text-gray-800 hover:text-white px-6"
                              >
                                <a
                                  href={subpage.href}
                                  className="block hover:text-[#ffffff]  py-2  "
                                >
                                  {subpage.title}
                                </a>
                              </li>
                            ))}
                            <span className="absolute bottom-0 left-0 w-0 bg-[#0076BC] h-0.5 group-hover:w-full transition-all duration-300"></span>
                          </ul>
                        </>
                      ) : (
                        <a href={href} className="hover:text-[#003152]">
                          {title}
                        </a>
                      )}
                      <span className="absolute bottom-0 left-0 w-0 bg-[#0076BC] h-0.5 group-hover:w-full transition-all duration-300"></span>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* <button className="hidden lg:block bg-[#0076BC] text-white px-4 py-3 uppercase font-medium ">
            Make an Appoinment
          </button> */}
          </div>
          {/* <div className="hidden lg:block lg:flex items-center gap-2">
            <span className="flex justify-center items-center  bg-[#0076BC] h-10 w-10 rounded-full">
              <i className="fa-solid fa-phone text-white"></i>
            </span>
            <a href="tel:+2348094325555">
              <p className=" text-xl">+234 809 432 5555</p>
            </a>
          </div> */}
        </div>

        {/* Mobile Nav Menu */}
        <div className="lg:hidden flex flex-wrap justify-between items-center px-5">
          <img
            className="w-[150px] lg:w-[200px]" // Adjusted logo width for smaller screens
            src={require("../../Assets/main-logo-white.png")}
            alt="Pushkal Logo"
          />

          <div>
            <AnimatePresence>
              {isMenuOpen && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="fixed top-0 left-0 z-40 h-full py-20 bg-white shadow-xl overflow-auto" // Full screen overlay
                >
                  <ul className="grid gap-2 p-5">
                    {HeaderNavigation.map((route, idx) => (
                      <motion.li
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{
                          type: "spring",
                          stiffness: 260,
                          damping: 20,
                          delay: 0.1 + idx / 10,
                        }}
                        key={route.title}
                        className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr border to-blue-50"
                      >
                        {route.subpages ? (
                          <>
                            <a
                              className="flex items-center justify-between w-full p-3 text-black rounded-xl bg-[#fff]"
                              href={route.href}
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubMenuToggle(idx);
                              }}
                            >
                              <span className="flex gap-1 text-lg">
                                {route.title}
                              </span>
                              <span className="rounded-full">
                                {openSubMenu === idx ? (
                                  <ArrowDown />
                                ) : (
                                  <ArrowUp />
                                )}
                              </span>
                            </a>
                            {openSubMenu === idx && (
                              <SubMenu subpages={route.subpages} />
                            )}
                          </>
                        ) : (
                          <Link
                            to={route.href}
                            className="flex items-center justify-between w-full p-3 text-black rounded-xl bg-[#fff]"
                            onClick={handleMenuItemClick}
                          >
                            <span className="flex gap-1 text-lg">
                              {route.title}
                            </span>
                          </Link>
                        )}
                      </motion.li>
                    ))}
                  </ul>
                  {/* <div className="flex items-center justify-center gap-2 p-5">
                    <span className="flex justify-center items-center bg-[#0076BC] h-10 w-10 rounded-full">
                      <i className="fa-solid fa-phone text-white"></i>
                    </span>
                    <a href="tel:+2348094325555">
                      <p className="text-sm">+234 809 432 5555</p>
                    </a>
                  </div>
                  <button className="mx-4 my-5 bg-[#0076BC] text-white px-4 py-3 uppercase font-medium rounded">
                    Make an Appointment
                  </button> */}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className=" ">
            <a className="" href="#" onClick={handleToggle}>
              <Hamburger toggled={isMenuOpen} size={25} />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
