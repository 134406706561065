import React from "react";

const OurJourney = () => {
  return (
    <div>
      <div class="pb-20 pt-20 bg-[#ddf2ff]">
        <div class="max-w-7xl mx-auto lg:w-full grid grid-cols-9 px-2 ">
          {/* <!-- Stack 1 --> */}
          <div class="col-span-4 lg:w-[200px]  border-l-4 border-[#0076BC] rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ">
            <div class="w-full lg:w-[520px] rounded-md h-full bg-gray-200 p-6 md:pl-7 ">
              <h1 class=" text-xl text-black font-medium py-2">1950</h1>
              <p class="text-black lg:text-sm text-xs">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div class="relative col-span-1 w-full h-full flex justify-center items-center">
            <div class="h-full w-1 bg-[#0076BC]"></div>
            <div class="absolute w-6 h-6 rounded-full bg-[#0076BC] z-10 text-white text-center">
              1
            </div>
          </div>
          <div class="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 2 --> */}
          <div class="col-span-4 w-full h-full"></div>
          <div class="relative col-span-1 w-full h-full flex justify-center items-center">
            <div class="h-full w-1 bg-[#0076BC]"></div>
            <div class="absolute w-6 h-6 rounded-full bg-[#0076BC] z-10 text-white text-center">
              2
            </div>
          </div>
          <div class="col-span-4 w-full h-full border-r-4 border-[#0076BC] rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div class="w-full  rounded-md h-full bg-gray-200 p-6 md:pl-4">
              <h1 class="text-black text-xl font-medium py-2">1952</h1>
              <p class="text-black sm:text-sm text-xs">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>

          {/* <!-- Stack 3 --> */}
          <div class="col-span-4 w-full h-full border-l-4 border-[#0076BC] rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div class="w-full lg:w-[520px] h-full rounded-md bg-gray-200  p-6 md:pl-4">
              <h1 class="text-black text-xl font-medium py-2">1960 - 1970</h1>
              <p class="text-black sm:text-sm text-xs">
                CLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div class="relative col-span-1 w-full h-full flex justify-center items-center">
            <div class="h-full w-1 bg-[#0076BC]"></div>
            <div class="absolute w-6 h-6 rounded-full bg-[#0076BC] z-10 text-white text-center">
              3
            </div>
          </div>
          <div class="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 4 --> */}
          <div class="col-span-4 w-full h-full"></div>
          <div class="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div class="h-full w-1 bg-[#0076BC] pr-"></div>
            <div class="absolute w-6 h-6 rounded-full bg-[#0076BC] z-10 text-white text-center">
              4
            </div>
          </div>
          <div class="col-span-4 w-full h-full border-r-4 border-[#0076BC] rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div class="w-full  rounded-md h-full bg-gray-200  p-6 md:pl-4">
              <h1 class="text-black text-xl font-medium py-2">1976</h1>
              <p class="text-black sm:text-sm text-xs">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>

          {/* <!-- Stack 5 --> */}
          <div class="col-span-4 w-[200px] h-full border-l-4 border-[#0076BC] pr-10 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div class="w-full lg:w-[520px] rounded-md h-full bg-gray-200  p-6 md:pl-4">
              <h1 class="text-black text-xl font-medium py-2">1977</h1>
              <p class="text-black sm:text-sm text-xs">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div class="relative col-span-1 w-full h-full flex justify-center items-center">
            <div class="h-full w-1 bg-[#0076BC]"></div>
            <div class="absolute w-6 h-6 rounded-full bg-[#0076BC] z-10 text-white text-center">
              5
            </div>
          </div>
          <div class="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 6 --> */}
          <div class="col-span-4 w-full h-full"></div>
          <div class="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div class="h-full w-1 bg-[#0076BC] pr-"></div>
            <div class="absolute w-6 h-6 rounded-full bg-[#0076BC] z-10 text-white text-center">
              6
            </div>
          </div>
          <div class="col-span-4 w-full h-full border-r-4 border-[#0076BC] rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div class="w-full  rounded-md h-full bg-gray-200  p-6 md:pl-4">
              <h1 class="text-black text-xl font-medium py-2">1980 - 1990</h1>
              <p class="text-black sm:text-sm text-xs">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurJourney;
