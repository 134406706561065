import React from "react";
import "./App.css";
import AppRoutes from "./Routes/Routes";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";
import CompanyOverview from "./Pages/CompanyOverview";

import { Provider } from "react-redux";
import store from "./Redux/store";

function App() {
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  );
}

export default App;
